import * as React from "react";
import "./app.css";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFound from "pages/NotFound";
import Users from "pages/Users";
import UsersDetails from "pages/UsersDetails";
import UboDetail from "pages/UboDetail";
import DirectorDetail from "pages/DirectorDetail";
import CompanyDetail from "pages/CompanyDetail";
import Login from "pages/Login";
import Companies from "pages/Companies";
import CustomersDetails from "pages/CustomersDetails";
import Customers from "pages/Customers";
import Offers from "pages/Offers";
import OfferDetail from "pages/OfferDetail";
import ValidationTypeMatcher from "pages/ValidationTypeMatcher";
import TaskList from "pages/TaskList";
import TaskListHistory from "pages/TaskHistoryList";
import TaskListAccount from "pages/TaskListAccount";
import TaskListCompanyFormation from "pages/TaskListCompanyFormation";
import { getIsLoggedIn } from "./store/reducers/app.reducer";
import PaymentsOutgoing from "pages/PaymentsOutgoing";
import PaymentsIncoming from "pages/PaymentsIncoming";
import AccountsCustomer from "pages/AccountsCustomer";
import AccountsRevenue from "pages/AccountsRevenue";
import AccountRevenueDetail from "pages/AccountRevenueDetail";
import AccountCustomerDetail from "pages/AccountCustomerDetail";
import NewBankTransfer from "pages/NewBankTransfer";
import PaymentsInternalTransfer from "pages/PaymentsInternalTransfer";
import TaskListAccountHistory from "pages/TaskHistoryListAccount";
import TaskListCompanyFormationHistory from "pages/TaskHistoryListCompanyFormation";
import Conversions from "pages/Conversions";
import AccountBalanceDetail from "pages/AccountBalanceDetail";
import Balances from "pages/Balances";
import PaymentsMakePayment from "pages/PaymentsMakePayment";
import dashboard from "pages/Dashboard";
import UsersUpdateInfo from "pages/UsersUpdateInfo";
import AccessLogs from "pages/AccessLogs";
import Adresses from "pages/Addresses";
import UpdateCustomerInfo from "pages/UpdateCustomerInfo";
import Products from "pages/Products";
import OffersPage from "pages/OffersPage";
import CompanyPage from "pages/CompanyPage";
import DocumentsInfo from "pages/DocumentsInfo";
import UpdateFinancialInfo from "pages/UpdateFinancialInfo";
import FinancialInfoPage from "pages/FinancialInfoPage";
import Profiles from "pages/Profiles";
import AccountsPage from "pages/AccountsPage";
import History from "pages/History";
import VerificationTaskPage from "pages/VerificationTaskPage";
import CustomerAccountTasks from "pages/CustomerAccountTasks";
import CompanyTaskPage from "pages/CompanyTaskPage";
import UpdateCompanyInfo from "pages/UpdateCompanyInfo";
import Directors from "pages/Directors";
import UBO from "pages/UBO";
import InvestmentsInfoPage from "pages/InvestmentsInfoPage";
import InvestmentsPreferencesPage from "pages/InvestmentsPreferencesPage";
import OpportunitiesPage from "pages/OpportunitiesPage";
import OpportunityCreatePage from "pages/OpportunityCreatePage";
import OpportunityDetails from "pages/OpportunityDetails";
import OpportunityDetailsEdit from "pages/OpportunityDetailsEdit";
import CustomersRedeemOffer from "pages/CustomersRedeemedOffer";
import ProductCreationPage from "pages/ProductCreationPage";
import ProductDetails from "pages/ProductDetails";
import InvestmentsPage from "pages/InvestmentsPage";
import News from "pages/NewsPage";
import NewsDetails from "pages/NewsDetails";
import NewsUpdate from "pages/NewsUpdate";
import NewsCreate from "pages/NewsCreate";
import CryptoWalletsPage from "pages/CryptoWalletsPage";
import CryptoWalletPage from "pages/CryptoWalletPage";
import CryptoPayments from "pages/CryptoPayments";
import CryptoPaymentDetails from "pages/CryptoPaymetDetails";
import CryptoWalletTransactions from "pages/CryptoWalletTransactions";
import CryptoWalletBalances from "pages/CryptoWalletBalances";
import DashboardProductDetails from "pages/DashboardProductDetails";
import CustomerWallets from "pages/CustomerWalletsPage";
import CustomerCryptoWalletsPage from "pages/CustomerCryptoWalletPage";
import CryptoBalanceDetails from "pages/CryptoBalanceDetails";
import PaymentsInternalConversion from "pages/PaymentsInternalConversion";
import ConversionsChangeFx from "pages/ConversionsChangeFx";
import CryptoPaymentCreate from "pages/CryptoPaymentCreate";
import CryptoWalletBeneficiares from "pages/CryptoWalletBeneficiares";
import CryptoPaymentTransfer from "pages/CryptoPaymentTransfer";
import CommerceCart from "pages/CommerceCart";
import CommerceProduct from "pages/CommerceProduct";
import CommercePayment from "pages/CommercePayment";
import LandPayments from "pages/LandPayments";
import LandBuyers from "pages/LandBuyers";
import LandPrepurchases from "pages/LandPrepurchases";
import ReconciliationList from "pages/ReconciliationList";
import TaskProcess from "pages/TaskProcess";
import PaymentsInternalBulk from "pages/PaymentsInternalBulk";
import PaymentsInternalNewBulk from "pages/PaymentsInternalNewBulk";
import PaymentsInternalBulkDetails from "pages/PaymentsInternalBulkDetails";
import PaymentsUp from "pages/PaymentsUp";
import PaymentsTransactions from "pages/PaymentsTransactions";
import KYCLevelsPage from "pages/KYCLevelsPage";
import GiftCardsPrograms from "pages/GiftCardsPrograms";
import GiftCards from "pages/GiftCards";
import GiftCardDetails from "pages/GiftCardDetails";
import PaymentsAcquiring from "pages/PaymentsAcquiring";
import Rewards from "pages/Rewards";
import GiftCardsProgramDetails from "pages/GiftCardsProgramDetails";
import GiftCardProgramBulk from "pages/GiftCardProgramBulk";

export const QuidRoutes: React.FC = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);

  return isLoggedIn ? (
    <Switch>
      <Route exact path="/commerce/cart" component={CommerceCart} />
      <Route path="/commerce/payments" component={CommercePayment} />
      <Route exact path="/commerce/products" component={CommerceProduct} />
      <Route exact path="/land/payments" component={LandPayments} />
      <Route path="/land/buyers" component={LandBuyers} />
      <Route exact path="/land/prepurchase" component={LandPrepurchases} />
      <Route path="/reconciliation" component={ReconciliationList} />
      <Route
        path="/crypto/:partyId/wallets/:id/transactions"
        component={CryptoWalletTransactions}
      />
      <Route
        path="/crypto/:partyId/wallets/:walletId/balances/smartContracts/:smartContractId"
        component={CryptoBalanceDetails}
      />
      <Route
        path="/crypto/:partyId/wallets/:id/balances"
        component={CryptoWalletBalances}
      />
      <Route
        path="/crypto/:partyId/wallets/:id/beneficiaries"
        component={CryptoWalletBeneficiares}
      />
      <Route path="/crypto/:partyId/wallets/:id" component={CryptoWalletPage} />
      <Route path="/crypto/wallets" component={CryptoWalletsPage} />
      <Route
        exact
        path="/crypto/payment/create"
        component={CryptoPaymentCreate}
      />
      <Route
        exact
        path="/crypto/payment/transfer"
        component={CryptoPaymentTransfer}
      />
      <Route exact path="/crypto/payments" component={CryptoPayments} />
      <Route
        exact
        path="/crypto/payments/:id"
        component={CryptoPaymentDetails}
      />
      <Route exact path="/users" component={Users} />
      <Route exact path="/users/:id" component={UsersDetails} />
      <Route exact path="/users/:id/view" component={UsersUpdateInfo} />
      <Route
        exact
        path="/users/:userid/accessLogs/:sessionid"
        component={AccessLogs}
      />
      <Route exact path="/customers" component={Customers} />
      <Route exact path="/customers/:id" component={CustomersDetails} />
      <Route exact path="/customers/:id/taskprocess" component={TaskProcess} />
      <Route exact path="/customers/:id/view" component={UpdateCustomerInfo} />
      <Route exact path="/customers/:id/addresses" component={Adresses} />
      <Route exact path="/customers/:id/products" component={Products} />
      <Route exact path="/customers/:id/rewards" component={Rewards} />
      <Route
        exact
        path="/companies/:id/products/createproduct"
        component={ProductCreationPage}
      />
      <Route
        exact
        path="/customers/:id/products/createproduct"
        component={ProductCreationPage}
      />
      <Route
        exact
        path="/customers/:partyId/products/:productId"
        component={ProductDetails}
      />
      <Route
        exact
        path="/companies/:partyId/products/:productId"
        component={ProductDetails}
      />
      <Route path="/products/:id" component={DashboardProductDetails} />
      <Route exact path="/customers/:id/offers" component={OffersPage} />
      <Route exact path="/customers/:id/companies" component={CompanyPage} />
      <Route
        exact
        path="/customers/:id/financial"
        component={FinancialInfoPage}
      />
      <Route
        exact
        path="/customers/:id/financial/view"
        component={UpdateFinancialInfo}
      />
      <Route exact path="/customers/:id/documents" component={DocumentsInfo} />
      <Route exact path="/customers/:id/accounts" component={AccountsPage} />
      <Route exact path="/customers/:id/wallets" component={CustomerWallets} />
      <Route exact path="/customers/:id/profiles" component={Profiles} />
      <Route exact path="/customers/:id/history" component={History} />
      <Route
        exact
        path="/customers/:id/crypto/wallets"
        component={CustomerCryptoWalletsPage}
      />
      <Route
        exact
        path="/customers/:id/pending/task"
        component={VerificationTaskPage}
      />
      <Route exact path="/customers/:id/kyclevels" component={KYCLevelsPage} />
      <Route
        exact
        path="/customers/:id/company/task"
        component={CompanyTaskPage}
      />
      <Route
        exact
        path="/customers/:id/account/task"
        component={CustomerAccountTasks}
      />
      <Route
        exact
        path="/customers/:id/investments/info"
        component={InvestmentsInfoPage}
      />
      <Route
        exact
        path="/customers/:id/investments/preferences"
        component={InvestmentsPreferencesPage}
      />
      <Route
        exact
        path="/customers/:partyId/acquiring/"
        component={PaymentsAcquiring}
      />
      <Route exact path="/companies" component={Companies} />
      <Route exact path="/conversions/list" component={Conversions} />
      <Route exact path="/conversions" component={Conversions} />
      <Route exact path="/companies/:id" component={CompanyDetail} />
      <Route
        exact
        path="/conversions/change/fx"
        component={ConversionsChangeFx}
      />
      <Route exact path="/companies/:id/view" component={UpdateCompanyInfo} />
      <Route exact path="/companies/:id/products" component={Products} />
      <Route exact path="/companies/:id/addresses" component={Adresses} />
      <Route exact path="/companies/:id/accounts" component={AccountsPage} />
      <Route exact path="/companies/:id/wallets" component={CustomerWallets} />
      <Route exact path="/companies/:id/documents" component={DocumentsInfo} />
      <Route exact path="/companies/:id/directors" component={Directors} />
      <Route exact path="/companies/:id/ubo" component={UBO} />
      <Route exact path="/companies/:id/rewards" component={Rewards} />
      <Route
        exact
        path="/companies/:id/financial"
        component={FinancialInfoPage}
      />
      <Route
        exact
        path="/companies/:id/financial/view"
        component={UpdateFinancialInfo}
      />
      <Route
        exact
        path="/companies/:id/investments/info"
        component={InvestmentsInfoPage}
      />
      <Route
        exact
        path="/companies/:id/investments/preferences"
        component={InvestmentsPreferencesPage}
      />
      <Route exact path="/companies/:id/offers" component={OffersPage} />
      <Route exact path="/companies/:id/kyclevels" component={KYCLevelsPage} />
      <Route
        exact
        path="/companies/:companyId/directors/:id"
        component={DirectorDetail}
      />
      <Route exact path="/companies/:companyId/ubo/:id" component={UboDetail} />
      <Route exact path="/rewards" component={Rewards} />
      <Route exact path="/offers" component={Offers} />
      <Route exact path="/offers/:id" component={OfferDetail} />
      <Route
        exact
        path="/offers/:id/customers"
        component={CustomersRedeemOffer}
      />
      <Route exact path="/cards/gift-cards" component={GiftCards} />
      <Route
        exact
        path="/cards/gift-cards/:id/details"
        component={GiftCardDetails}
      />
      <Route
        exact
        path="/cards/gift-cards/programs"
        component={GiftCardsPrograms}
      />
      <Route
        exact
        path="/cards/gift-cards/programs/:id/details"
        component={GiftCardsProgramDetails}
      />
      <Route
        exact
        path="/cards/gift-cards/programs/bulk/new"
        component={GiftCardProgramBulk}
      />
      <Route exact path="/accounts/balances" component={Balances} />
      <Route
        exact
        path="/accounts/:accountId/balances/:id"
        component={AccountBalanceDetail}
      />
      <Route
        exact
        path="/accounts/:accountId/balances/:id/pay"
        component={PaymentsMakePayment}
      />
      <Route
        exact
        path="/payments/transactions"
        component={PaymentsTransactions}
      />
      <Route exact path="/payments/acquiring" component={PaymentsAcquiring} />
      <Route exact path="/payments/incoming" component={PaymentsIncoming} />
      <Route exact path="/accounts/customers" component={AccountsCustomer} />
      <Route exact path="/accounts/revenues" component={AccountsRevenue} />
      <Route exact path="/revenue/:id" component={AccountRevenueDetail} />
      <Route exact path="/accounts/:id" component={AccountCustomerDetail} />
      <Route exact path="/payments/outgoing" component={PaymentsOutgoing} />
      <Route exact path="/payments/bulk" component={PaymentsInternalBulk} />
      <Route
        exact
        path="/payments/bulk/new"
        component={PaymentsInternalNewBulk}
      />
      <Route
        exact
        path="/payments/bulk/:bulkId"
        component={PaymentsInternalBulkDetails}
      />
      <Route exact path="/pay/bank" component={NewBankTransfer} />
      <Route exact path="/pay/internal" component={PaymentsInternalTransfer} />
      <Route exact path="/pay/balance-adjustments" component={PaymentsUp} />
      <Route
        exact
        path="/pay/conversion"
        component={PaymentsInternalConversion}
      />
      <Route exact path="/opportunities" component={OpportunitiesPage} />
      <Route
        exact
        path="/opportunities/create"
        component={OpportunityCreatePage}
      />
      <Route exact path="/opportunities/:id" component={OpportunityDetails} />
      <Route
        exact
        path="/opportunities/:id/edit"
        component={OpportunityDetailsEdit}
      />
      <Route exact path="/investments" component={InvestmentsPage} />
      <Route exact path="/news" component={News} />
      <Route exact path="/news/create" component={NewsCreate} />
      <Route exact path="/news/:id" component={NewsDetails} />
      <Route exact path="/news/:id/view" component={NewsUpdate} />
      <Route exact path="/task" component={TaskList} />
      <Redirect from="/login" to="/" />
      <Route
        path="/:history/:taskDefinition/:taskId"
        component={ValidationTypeMatcher}
      />
      <Route exact path="/:history/task" component={TaskListHistory} />
      <Route
        exact
        path="/:history/accounts"
        component={TaskListAccountHistory}
      />
      <Route
        exact
        path="/:history/companyformations"
        component={TaskListCompanyFormationHistory}
      />
      <Route exact path="/" component={dashboard} />
      <Route exact path="/tasksaccounts" component={TaskListAccount} />
      <Route
        exact
        path="/taskscompanyformations"
        component={TaskListCompanyFormation}
      />
      <Route
        path="/:taskDefinition/:taskId"
        component={ValidationTypeMatcher}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  ) : (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Redirect from="*" to="/login" />
    </Switch>
  );
};
